import React from "react"
import {
  Flex,
  Text,
  Box,
  Stack,
  Icon,
  Link as ChakraLink,
  Image,
} from "@chakra-ui/core"

import image from "../../images/43122.jpg"
import startImg from "../../images/Sonar_logo_V4-250x131.png"
import imgLogo from "../../images/logo.png"

export default function Home() {
  return (
    <>
      <Flex alignItems="center" mr={10} ml={10}>
        <Flex
          flex={10}
          direction="column"
          flexBasis="65%"
          mr={{ base: 10, md: 20 }}
        >
          <Stack spacing={5} mb={10}>
            <Text fontSize="lg" fontWeight="600">
              Les impacts de la crise économique
            </Text>
            <Text>
              D'après l'Observatoire français des conjonctures économiques
              (OFCE) :{" "}
              <Text as="em">
                le risque est grand de voir s’enclencher une spirale récessive :
                faillites et réduction de l’emploi conduiront à une réduction du
                revenu des ménages qui alimentera la réduction de l’activité.
              </Text>
            </Text>

            <Text>
              Les communes vont avoir besoin d’outils pour mieux anticiper les
              besoins et agir en s’appuyant sur des modèles de prévision
              fiables.
            </Text>

            <Text>
              Pour anticiper les effets de la crise économique, le{" "}
              <ChakraLink href="http://www.lecompas.fr/" isExternal>
                Compas
                <Icon name="external-link" mx="2px" />
              </ChakraLink>{" "}
              a construit un{" "}
              <Text as="span" style={{ fontWeight: "600" }}>
                baromètre©Compas{" "}
              </Text>
              décliné en deux indices : le budget des ménages et l'emploi.
            </Text>
          </Stack>
        </Flex>
        <Flex
          flexBasis="20%"
          flexDirection="column"
          fontSize="xs"
          alignItems="center"
          textAlign="center"
        >
          <Text as="strong">Mieux nous connaître</Text>
          <Image src={imgLogo} my={1} />
          <ChakraLink
            href="http://www.lecompas.fr"
            isExternal
            backgroundColor="rgb(22,171,228)"
            color="white"
            p="1"
            my={1}
          >
            lecompas.fr
            <Icon name="external-link" mx="2px" />
          </ChakraLink>
        </Flex>
      </Flex>
    </>
  )
}
