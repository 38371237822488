import React, { useState, useRef, useReducer } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/fragilite-economique/home"
import SelectTerr from "../components/fragilite-economique/select"
import AppCore from "../components/fragilite-economique/appCore"
import imgIllustration from "../images/fragilite-economique2.svg"

/**
 * gestion de l'état du composant
 * deux props :
 * - codzone
 * - indicateur sélectionné (0/1)
 */
function reducer(state, action) {
  switch (action.type) {
    case "setCodzone":
      return { ...state, codzone: action.codzone }
    case "setIndicateur":
      return { ...state, indicateur: parseInt(action.indicateur) }
    default:
      throw new Error()
  }
}

const ComparateurNumerique = () => {
  // const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)
  const [state, dispatch] = useReducer(reducer, {
    codzone: undefined,
    indicateur: 0,
  })
  const setCodzone = codzone => dispatch({ type: "setCodzone", codzone })
  const setIndicateur = indicateur =>
    dispatch({ type: "setIndicateur", indicateur })

  return (
    <Layout
      title="Les impacts de la crise économique"
      headerContainer={headerContainer}
      img={imgIllustration}
    >
      <SEO title="Baromètre Compas - Les impacts de la crise économique" />
      <Home
        codzone={state.codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
      />
      <SelectTerr
        codzone={state.codzone}
        indicateur={state.indicateur}
        setCodzone={setCodzone}
        setIndicateur={setIndicateur}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
      <AppCore
        codzone={state.codzone}
        indicateur={state.indicateur}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
    </Layout>
  )
}

export default ComparateurNumerique
