import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Les ménages avec au moins un emploi précaire.
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Stack spacing={5}>
            <Text>
              La perte d'emploi liée à la crise économique concerne dans un
              premier temps les ménages les plus précaires (CDD et intérim) puis
              ensuite des ménages qui peuvent travailler dans des secteurs
              d’activités fortement concernés par la crise sanitaire (spectacle,
              hôtellerie/ restauration, le bâtiment, voire le secteur de
              l’industrie des transports, …).
            </Text>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le chômage
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Le chômage partiel risque de faire passer en dessous du seuil de
            pauvreté certains de ces ménages entraînant des difficultés à payer
            le loyer ou les charges.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Les ménages entre le seuil de pauvreté et 200 € au-dessus.
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Nous prenons en compte dans ce baromètre les ménages qui ont un
            emploi mais dont celui-ci ne parvient qu’à les sortir légèrement du
            seuil de pauvreté français. Les ménages situés entre le seuil de
            pauvreté et 200 euros au-dessus sont pris en considération. Ces
            ménages <Text as="em">pauvres</Text> seront dans un premier temps
            les premiers impactés par la crise.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
)
