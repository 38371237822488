import React from "react"
import { Stack, Text, List, ListItem } from "@chakra-ui/core"

export default function Methodo() {
  return (
    <>
      <Stack spacing={5} fontSize="sm">
        <Text>
          L'indice de l’impact de la crise économique sur l’emploi s'appuie sur 7 indicateurs
          distincts calculés sur chacun des territoires observés :
        </Text>
        <List styleType="disc">
          <ListItem>les actifs de 15-64 ans en emploi précaires,</ListItem>
          <ListItem>
            les actifs de 15-64 ans du secteur de l’hôtellerie- restauration,
          </ListItem>
          <ListItem>les actifs de 15-64 ans du secteur du bâtiment,</ListItem>
          <ListItem>
            les actifs de 15-64 ans vivant dans un ménage sans voiture,
          </ListItem>
          <ListItem>
            les bas niveaux de formation parmi les plus de 15 ans non
            scolarisés,
          </ListItem>
          <ListItem>l’activité des jeunes de 15/24 ans,</ListItem>
          <ListItem>l’activité des seniors de 55 ans et plus.</ListItem>
        </List>
        <Text>
          Les composantes sont notées de 0 à 5. La note maximale possible est de
          35 et la valeur moyenne de la France métropolitaine est de 21.
        </Text>
      </Stack>
    </>
  )
}
