import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <Box>
    <Text mb={5} fontSize="sm">
      La crise économique des années 2008/2014 a permis de construire un modèle
      pour la mesure des effets en matière de pertes d'emplois. Nous nous sommes
      basés sur cette modélisation :
    </Text>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Les emplois précaires et les secteurs d'activités fragilisés
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Stack spacing={0}>
            <Text>
              Le premier impact de la crise économique de 2008 a été celui sur
              les personnes à emploi précaire. Ce sont ensuite les secteurs
              d’activités fragiles qui ont le plus souffert (à l’époque le
              bâtiment et l’industrie).
            </Text>
            <Text>
              Pour constuire le baromètre, nous considérons comme les plus
              fragiles les secteurs de l'hôtellerie-restauration et le Bâtiment.
            </Text>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            La mobilité professionnelle
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            La question de la mobilité des actifs est devenue un élément clé de
            l’insertion professionnelle pour les ménages à plus bas niveau de
            qualification.
          </Text>
          <Text>
            C'est pourquoi nous avons choisi d'intégrer dans le baromètre la
            part des actifs n'ayant pas de voiture.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le niveau de formation
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Si dans un premier temps les actifs les plus formés ont été moins
            touchés par le chômage que les actifs à faible niveau de formation,
            dans un second temps les hauts niveaux de formation ont connu un
            rebond d’activité important, ce qui n’a pas été le cas des moins
            formés.
          </Text>
          <Text>
            Nous avons ainsi intégré dans le baromètre les actifs ayant un bas
            niveau de formation.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Les actifs
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            En début de crise, les jeunes ont été concernés par le chômage et en
            seconde partie les seniors (50 ans et plus).
          </Text>
          <Text>
            C'est pourquoi nous distinguons dans le baromètre les 15-24 ans
            actifs d'une part et les 55-64 ans actifs d'autre part.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Box>
)
