import React from "react"
import { Stack, Text, List, ListItem } from "@chakra-ui/core"

export default function Methodo() {
  return (
    <>
      <Stack spacing={5} fontSize="sm">
        <Text>
          L’indice de l’impact de la crise économique sur le budget des ménages
          s'appuie sur 4 indicateurs distincts calculés sur chacun des
          territoires observés :
        </Text>
        <List styleType="disc">
          <ListItem>les ménages avec au moins un emploi précaire,</ListItem>
          <ListItem>une estimation du chômage récent,</ListItem>
          <ListItem>le taux de pauvreté,</ListItem>
          <ListItem>
            les ménages situés entre le seuil de pauvreté et 200 euros au-dessus
            du seuil de pauvreté.
          </ListItem>
        </List>
        <Text>
          Les composantes sont notées de 0 à 5. La note maximale possible est de
          20 et la valeur moyenne de la France métropolitaine est de 12.
        </Text>
      </Stack>
    </>
  )
}
